@use "../config" as *;
.waf-detail {
    .head-wrap {
        @extend %py-2;
        @extend %flex-sb-c;
    }
    .article-meta {
        .meta {
            @extend %font-12-pr;
            @extend %secondary-1000;
        }
        .meta-date {
            &::before {
                @include icon(calendar);
                @extend %mr-1;
            }
        }
    }
    .article-body {
        @extend %half-radius;
        @extend %p-3;
    }
    .article-head {
        border-radius: var(--full-radius) var(--full-radius) 0 0;
        @extend %relative;
    }
    .social {
        &-share-wrap {
            @extend %d-block;
            button {
                @extend %secondary-800-bg;
            }
        }
        &-wrap {
            @extend %flex;
        }
        .close,
        .share-label,
        .share-icon {
            @extend %d-none;
        }
    }
    .detail-title {
        font-weight: 700;
        @extend %neutral-900;
        @extend %my-0;
        @extend %pb-2;
        @extend %font-18;
    }
    video {
        max-width: 100%;
    }
    iframe {
        @extend %w-100;
        @extend %full-radius;
    }
}
// VIDEO DETAILS CSS
.waf-detail {
    &.waf-video-page {
        .title {
            @extend %secondary-1000;
        }
        .article-head {
            border-radius: 0;
            @extend %mt-0;
            .article-description {
                @extend %d-none;
            }
        }
        .article-body {
            @extend %px-0;
            @extend %transparent-bg;
        }
    }
}
// NEWS DETAILS CSS
.waf-detail {
    &.news-details {
        @extend %pb-10;
        .title {
            @extend %secondary-1000;
        }
        .article-head,
        .article-body {
            @extend %secondary-50-bg;
        }
        .article-body {
            border-radius: 0 0 var(--full-radius) var(--full-radius);
            @extend %px-0;
        }
        p:has(iframe) {
            @extend %flex-c-c;
        }
        .social-share.active .social-share-wrap {
            @extend %p-0;
            @extend %transparent-bg;
        }
    }
}
.related-listing {
    &::after {
        content: "";
        height: .1rem;
        @include position(0, null, null, 0, absolute);
        @extend %w-100;
        @extend %d-block;
        @extend %neutral-100-bg-4;
    }
    .article-thumbnail {
        @extend %relative;
    }
}
.views-section,
.item-type-article .item-type-icon {
    @extend %d-none;
}
@media screen and (min-width: $tablet-min-width) {
    .waf-detail,
    .waf-listing {
        @include background(null, "patterns/common-pattern.png", bottom/cover fixed);
    }
    .waf-detail {
        iframe {
            // padding-inline: var(--space-15)
            border-radius: var(--space-4);
        }
        .article-body {
            padding: var(--space-6);
        }
    }
    // NEWS DETAILS CSS
    .waf-detail {
        &.news-details {
            .article-head {
                margin-top: var(--space-15-neg);
                &:first-child {
                    display: none;
                }
            }
            .article-head,
            .article-body {
                padding-inline: var(--space-5);
                margin-inline: var(--space-15);
                background-color: clr(neutral-50);
            }
            .view-count,
            .item-type-article .item-type-icon {
                display: none;
            }
        }
    }
    // VIDEO DETAILS CSS
    .waf-detail {
        &.waf-video-page {
            .article-head,
            .article-body {
                padding-top: var(--space-3);
                padding-inline: var(--space-6);
                margin-inline: var(--space-15);
            }
        }
    }
}